import { Consent } from '@aclass/admin/storage/models/consent';
import { Customer } from '@aclass/admin/storage/models/customer';
import { Fk, Model } from '@aclass/core/decorators/orm.decorator';
import { Model as Entity } from 'redux-orm';

export interface IConsentCustomerMappingData {

    readonly consentId: Consent | number | null;

    readonly customerId: Customer | number | null;
}

@Model({
    name: 'ConsentCustomerMapping',
    idAttribute: 'consentId'
})
export class ConsentCustomerMapping extends Entity<IConsentCustomerMappingData> {

    @Fk({ to: 'Consent', relatedName: 'relatedConsentCustomerMappings' }) readonly consentId: Consent | null;

    @Fk({ to: 'Customer', relatedName: 'relatedConsentCustomerMappings' }) readonly customerId: Customer | null;

}
