import { OrmManager } from '@aclass/admin/managers';
import { CustomerViewStateResolver } from '@aclass/admin/resolvers';
import { viewDocumentRs, viewSendEmailData } from '@aclass/admin/rest/responses';
import { CustomerStory, DashboardStory, OpportunityStory, OrmStory, QuoteStory, RootStory, SystemStory } from '@aclass/admin/storage/actions';
import { IEpicDi } from '@aclass/admin/storage/helpers';
import {
    Customer,
    EmailTemplate,
    IConsentCustomerMappingData,
    IConsentData,
    ICountryData,
    ICustomerEmailMessageLogData,
    ICustomerLogData,
    IDocumentCustomerMappingData,
    IDocumentData,
    IDocumentEmailTemplateMappingData,
    IDocumentOfflineBookingMapping,
    IDocumentOpportunityMappingData,
    IEmailTemplateData,
    IOpportunityEmailMessageLogData,
    IOpportunityLogData,
    IPCTourPackageData,
} from '@aclass/admin/storage/models';
import { orm } from '@aclass/admin/storage/orm';
import { IAdminState } from '@aclass/admin/storage/states';
import { readToBase64, tryToSaveFile, IFileData } from '@aclass/core/base/file.reader';
import { SystemNotification } from '@aclass/core/base/system.notification';
import { findById } from '@aclass/core/helpers/orm';
import { Order } from '@aclass/core/rest/order';
import { DataSearchRqData } from '@aclass/core/rest/requests/data.search.rq';
import { dispatchActions, Response, Transformed } from '@aclass/core/rest/response';
import { IDataSearchRs } from '@aclass/core/rest/responses/data.search.rs';
import { Action } from '@aclass/core/storage/action';
import { ICustomerData } from '@aclass/core/storage/models/customer';
import { IOfflineBookingData } from '@aclass/core/storage/models/offline.booking';
import { IOpportunityData } from '@aclass/core/storage/models/opportunity';
import { IPipelineLevelData } from '@aclass/core/storage/models/pipeline.level';
import { HttpResponse } from '@angular/common/http';
import { ofType, ActionsObservable, StateObservable } from 'redux-observable';
import { concat, merge, of, zip } from 'rxjs';
import { filter, mergeMap, switchMap } from 'rxjs/operators';

type transformed = Transformed<ICustomerData, {

    emails?: Array<Transformed<ICustomerEmailMessageLogData>>;

    logs?: Array<Transformed<ICustomerLogData>>;

    documents?: Array<Transformed<IDocumentData>>;

    country?: Transformed<ICountryData>;

    documentMappings?: Array<Transformed<IDocumentCustomerMappingData>>;

    consents?: Array<Transformed<IConsentData>>;

    consentsMappings?: Array<Transformed<IConsentCustomerMappingData>>;

    offlineBookings?: Array<Transformed<IOfflineBookingData, {

        documents?: Array<Transformed<IDocumentData>>;

        documentMappings?: Array<Transformed<IDocumentOfflineBookingMapping>>;
    }>>;

    enquiries?: Array<Transformed<IOpportunityData, {

        package?: Transformed<IPCTourPackageData>;

        pipeline?: Transformed<IPipelineLevelData>;

        logs?: Array<Transformed<IOpportunityLogData>>;

        emails?: Array<Transformed<IOpportunityEmailMessageLogData>>;

        documents?: Array<Transformed<IDocumentData>>;

        documentMappings?: Array<Transformed<IDocumentOpportunityMappingData>>;
    }>>;
}>;

function populateTransformed(rs: Response<transformed>): Array<Action> {
    const { data, includes } = rs.body;
    const actions = [
        OrmStory.populateCustomers([data])
    ];
    if (!includes) {
        return actions;
    }
    let documents: Array<IDocumentData> = [];
    let consents: Array<IConsentData> = [];
    if ('emails' in includes && includes.emails.length) {
        actions.push(OrmStory.populateCustomerEmailMessageLogs(includes.emails.map(r => r.data)));
    }
    if ('logs' in includes && includes.logs.length) {
        actions.push(OrmStory.populateCustomerLogs(includes.logs.map(r => r.data)));
    }
    if ('consents' in includes && includes.consents.length) {
        consents = consents.concat(includes.consents.map(r => r.data));
        actions.push(OrmStory.populateConsent(consents));
    }
    if ('consentsMappings' in includes && includes.consentsMappings.length) {
        actions.push(OrmStory.populateConsentCustomerMappings(includes.consentsMappings.map(r => r.data)));
    }
    if ('documents' in includes && 'documentMappings' in includes && includes.documents.length) {
        documents = documents.concat(includes.documents.map(r => r.data));
        actions.push(OrmStory.populateDocumentCustomerMappings(includes.documentMappings.map(r => r.data)));
    }
    if ('country' in includes && includes.country && includes.country.data) {
        actions.push(OrmStory.populateCountries([includes.country.data]));
        actions.push(CustomerStory.updateCountryEditPage([includes.country.data.id]));
    }
    if ('offlineBookings' in includes && includes.offlineBookings.length) {
        const bookings: Array<IOfflineBookingData> = [];
        let bDocuments: Array<IDocumentOfflineBookingMapping> = [];
        includes.offlineBookings.forEach(booking => {
            bookings.push(booking.data);
            const rIncludes = booking.includes;
            if (!rIncludes) {
                return;
            }
            if ('documents' in rIncludes && 'documentMappings' in rIncludes && rIncludes.documents.length) {
                documents = documents.concat(rIncludes.documents.map(r => r.data));
                bDocuments = bDocuments.concat(rIncludes.documentMappings.map(r => r.data));
            }
        });
        actions.push(OrmStory.populateOfflineBookings(bookings));
        if (bDocuments.length) {
            actions.push(OrmStory.populateDocumentOfflineBookingMappings(bDocuments));
        }
    }
    if ('enquiries' in includes && includes.enquiries.length) {
        const opportunities: Array<IOpportunityData> = [];
        const packages: { [k: string]: IPCTourPackageData } = { };
        const pipelines: { [k: string]: IPipelineLevelData } = { };
        let oDocuments: Array<IDocumentOpportunityMappingData> = [];
        let oEmails: Array<IOpportunityEmailMessageLogData> = [];
        let oLogs: Array<IOpportunityLogData> = [];
        includes.enquiries.forEach(opportunity => {
            opportunities.push(opportunity.data);
            const rIncludes = opportunity.includes;
            if (!rIncludes) {
                return;
            }
            if ('documents' in rIncludes && 'documentMappings' in rIncludes && rIncludes.documents.length) {
                documents = documents.concat(rIncludes.documents.map(r => r.data));
                oDocuments = oDocuments.concat(rIncludes.documentMappings.map(r => r.data));
            }
            if ('emails' in rIncludes && rIncludes.emails.length) {
                oEmails = oEmails.concat(rIncludes.emails.map(r => r.data));
            }
            if ('logs' in rIncludes && rIncludes.logs.length) {
                oLogs = oLogs.concat(rIncludes.logs.map(r => r.data));
            }
            if ('package' in rIncludes && rIncludes.package.data) {
                packages[rIncludes.package.data.id] = rIncludes.package.data;
            }
            if ('pipeline' in rIncludes && rIncludes.pipeline.data) {
                pipelines[rIncludes.pipeline.data.id] = rIncludes.pipeline.data;
            }
        });
        actions.push(OrmStory.populateOpportunities(opportunities));
        if (Object.values(packages).length) {
            actions.push(OrmStory.populatePcTourPackages(Object.values(packages)));
        }
        if (Object.values(pipelines).length) {
            actions.push(OrmStory.populatePipelineLevels(Object.values(pipelines)));
        }
        if (oDocuments.length) {
            actions.push(OrmStory.populateDocumentOpportunityMappings(oDocuments));
        }
        if (oEmails.length) {
            actions.push(OrmStory.populateOpportunityEmailMessageLogs(oEmails));
        }
        if (oLogs.length) {
            actions.push(OrmStory.populateOpportunityLogs(oLogs));
        }
    }
    if (documents.length) {
        actions.push(OrmStory.populateDocuments(documents));
    }

    return actions;
}

export const customerModuleEpic = (action: ActionsObservable<Action>, state: StateObservable<IAdminState>, { http, instanceManager, location }: IEpicDi) => merge(
    action.pipe(
        ofType(CustomerStory.SEARCH_PAGE_DRY_RUN),
        filter(() => !state.value.customerModule.get('searchPageInitialized')),
        switchMap(() => [
            // Reset prev records state
            CustomerStory.updateFormSearchPage({ }),
            CustomerStory.importRecordsOnSearchPage([]),
            CustomerStory.updatePaginationOnSearchPage(null),
            CustomerStory.updateOrderOnSearchPage(null),
            CustomerStory.updateSearchLockOnSearchPage(null),
            CustomerStory.updateCollapsedSearchPage(null),
            // Unlock page
            CustomerStory.updateSearchPageInitState(true),
        ])
    ),
    action.pipe(
        ofType(CustomerStory.SEARCH_PAGE_DELETE_BULK),
        mergeMap(() => {
            const module = state.value.customerModule;
            const ids = module.get('searchPageSelectedCustomers').toArray();

            return concat(of(CustomerStory.updateSearchLockOnSearchPage(true)), http.post('customers/bulk-delete', { ids }).pipe(
                dispatchActions(
                    (rs: Response<number>) => [
                        OrmStory.updateCustomers(ids.map(id => ({ id, deleted: true }))),
                        CustomerStory.updateSelectedCustomersOnSearchPage([]),
                        CustomerStory.updateSearchLockOnSearchPage(false),
                        SystemStory.showNotification(SystemNotification.success('Success', `"${ rs.body }" persons removed, this records'll be removed from search results after next search`)),
                    ],
                    () => [
                        CustomerStory.updateSearchLockOnSearchPage(false),
                    ]
                )));
        }),
    ),
    action.pipe(
        ofType(CustomerStory.SEARCH_PAGE_SUBMIT),
        mergeMap(({ payload }: Action<boolean>) => {
            const module = state.value.customerModule;
            const rq: DataSearchRqData = {
                where: {
                    brands: module.getIn(['searchPageForm', 'brands']),
                    locales: module.getIn(['searchPageForm', 'locales']),
                    customer: module.getIn(['searchPageForm', 'customer'], null),
                    createdAt: module.getIn(['searchPageForm', 'createdAt'], null),
                    updatedAt: module.getIn(['searchPageForm', 'updatedAt'], null),
                    notifiedAt: module.getIn(['searchPageForm', 'notifiedAt'], null),
                    sansOpportunities: module.getIn(['searchPageForm', 'sansOpportunities'], null),
                },
                pagination: module.get('searchPagePagination'),
                order: module.get('searchPageOrder'),
            };

            return concat(of(CustomerStory.updateSearchLockOnSearchPage(true)), http.post('customers/search', rq).pipe(
                dispatchActions(
                    (rs: Response<IDataSearchRs<Array<ICustomerData>>>) => [
                        OrmStory.populateCustomers(rs.body.results),
                        CustomerStory.importRecordsOnSearchPage(rs.body.results.map(r => r.id)),
                        CustomerStory.updateSearchLockOnSearchPage(false),
                        CustomerStory.updatePaginationOnSearchPage(rs.body.pagination),
                        CustomerStory.updateOrderOnSearchPage(rs.body.order),
                    ],
                    () => [
                        CustomerStory.updateSearchLockOnSearchPage(false),
                    ]
                )));
        }),
    ),
    action.pipe(
        ofType(CustomerStory.EDIT_PAGE_DRY_RUN),
        filter(({ payload }: Action<string>) => state.value.customerModule.get('editPageRecordId') !== payload),
        mergeMap(({ payload }: Action<string>) => http.get(`customers/${ payload }`).pipe(
            dispatchActions((rs: Response<transformed>) => [
                    CustomerStory.updateFormOnEditPage({ }),
                    CustomerStory.updateStackViewSegmentsEditPage(null),
                    ...populateTransformed(rs),
                    DashboardStory.updateLastCustomersOnSearchPage(payload),
                    CustomerStory.updateCustomerIdEditPage(payload),
                ],
                () => [
                    SystemStory.stopNavigate(CustomerViewStateResolver.STOP_EVENT_NAME),
                    SystemStory.navigate(['ao', 'persons']),
                ],
            ),
        )),
    ),
    action.pipe(
        ofType(CustomerStory.EDIT_PAGE_UPLOAD_DOCUMENTS),
        switchMap(({ payload }: Action<Array<File>>) => {
            const module = state.value.customerModule;
            const id = module.get('editPageRecordId');

            return concat(of(CustomerStory.updateUploadDocumentsLockEditPage(true)), zip(...payload.map(readToBase64)).pipe(
                switchMap((results: Array<IFileData>) => concat(http.post('document-enquire-data', { documents: results, ...{ customerId: id } })).pipe(
                    switchMap((rs: viewDocumentRs) => {
                        const collection: Array<IDocumentData> = rs.body.documents;
                        const actions = [
                            CustomerStory.updateUploadDocumentsLockEditPage(false)
                        ];
                        if (!collection.length) {
                            return actions;
                        }

                        return actions.concat([
                            OrmStory.populateDocuments(collection),
                            OrmStory.populateDocumentCustomerMappings(collection.map(r => ({ documentId: r.id, customerId: id }))),
                            OrmStory.reloadModel(OrmManager.RELOAD_CUSTOMER),
                            SystemStory.showNotification(SystemNotification.success('Success', `Document ${ collection.length > 1 ? 's' : '' } uploaded`)),
                        ]);
                    })
                )),
            ));
        }),
    ),
    action.pipe(
        ofType(CustomerStory.EDIT_PAGE_REMOVE_DOCUMENT),
        mergeMap(({ payload }: Action<IDocumentData['id']>) => concat(of(CustomerStory.updatePickedDocumentEditPage(payload)), http.delete(`document-enquire-data/${ payload }`).pipe(
            dispatchActions(() => [
                CustomerStory.updatePickedDocumentEditPage(null),
                OrmStory.dropDocuments([payload]),
                OrmStory.reloadModel(OrmManager.RELOAD_CUSTOMER),
                SystemStory.showNotification(SystemNotification.success('Success', 'Document removed'))
            ])
        ))),
    ),
    action.pipe(
        ofType(CustomerStory.EDIT_PAGE_DOWNLOAD_DOCUMENT),
        mergeMap(({ payload }: Action<IDocumentData['id']>) => concat(of(CustomerStory.updatePickedDocumentEditPage(payload)), http.get(`document-enquire-data/download/${ payload }`, { observe: 'response', responseType: 'text' }).pipe(
            mergeMap((rs: HttpResponse<string>) => {
                tryToSaveFile(rs);

                return of(CustomerStory.updatePickedDocumentEditPage(null));
            })
        ))),
    ),
    action.pipe(
        ofType(CustomerStory.EDIT_PAGE_TOGGLE_DELETED),
        mergeMap(({ payload }: Action<boolean>) => {
            const module = state.value.customerModule;
            const id = module.get('editPageRecordId');
            const rq: any = {
                customer: {
                    id,
                    deleted: payload
                },
                changesDescription: module.getIn(['editPageForm', 'log', 'message']),
            };
            const eventName = payload ? 'removed' : 'restored';

            return concat(of(CustomerStory.updateSaveLockEditPage(true)), http.post(`customers/toggle-deleted`, rq).pipe(
                dispatchActions((rs: Response<transformed>) => [
                    ...populateTransformed(rs),
                    OrmStory.reloadModel(OrmManager.RELOAD_CUSTOMER),
                    CustomerStory.updateSaveLockEditPage(false),
                    SystemStory.showNotification(SystemNotification.success('Success', `Person ${ eventName }`)),
                ])));
        })
    ),
    action.pipe(
        ofType(CustomerStory.EDIT_PAGE_SUBMIT),
        mergeMap(() => {
            const module = state.value.customerModule;
            const id = module.get('editPageRecordId');
            const form = module.get('editPageForm');
            const customer = findById<Customer>(orm.session(state.value.orm), Customer, id);

            return concat(of(CustomerStory.updateSaveLockEditPage(true)), http.post(`customers/check-customer`, { id, email: form.getIn(['customer', 'email']) }).pipe(switchMap(
                (response: Response<Transformed<ICustomerData> | null>) => {
                    if (!response.success) {
                        return [
                            CustomerStory.updateSaveLockEditPage(false),
                        ];
                    }
                    if (response.body) {
                        return [
                            OrmStory.populateCustomers([response.body.data]),
                            CustomerStory.updateCustomerMergerIdOnEditPage(response.body.data.id),
                            CustomerStory.updateSaveLockEditPage(false),
                        ];
                    }
                    const rq = {
                        customer: {
                            ...form.get('customer').toJS(),
                            updatedAt: customer.updatedAt,
                        },
                        changesDescription: form.getIn(['log', 'message']),
                    };

                    return http.put(`customers/${ id }`, rq).pipe(dispatchActions(
                        (rs: Response<transformed>) => [
                            ...populateTransformed(rs),
                            OrmStory.reloadModel(OrmManager.RELOAD_CUSTOMER),
                            RootStory.resetForm('log'),
                            // Reset opportunity state
                            OpportunityStory.updateOpportunityIdEditPage(null),
                            QuoteStory.updateOpportunityIdEditPage(null),
                            CustomerStory.updateSaveLockEditPage(false),
                            SystemStory.showNotification(SystemNotification.success('Success', 'Person saved'))
                        ],
                        () => [
                            CustomerStory.updateSaveLockEditPage(false),
                        ]
                    ));
                }
            )));
        }),
    ),
    action.pipe(
        ofType(CustomerStory.EDIT_PAGE_CUSTOMER_MERGER_SUBMIT),
        mergeMap(({ payload }: Action<{ from: ICustomerData['id'], to: ICustomerData['id'] }>) => {
            const module = state.value.opportunityModule;

            return concat(of(CustomerStory.updateSaveLockEditPage(true)), http.post(`customers/merge-customer`, { ...payload, changesDescription: module.getIn(['editPageForm', 'log', 'message']) }).pipe(dispatchActions(
                (rs: Response<transformed>) => {
                    const id = rs.body.data.id;
                    location.replaceState(['ao', 'persons', id].join('/'));

                    return [
                        ...populateTransformed(rs),
                        OrmStory.dropCustomers([payload.from]),
                        DashboardStory.updateLastCustomersOnSearchPage(id),
                        CustomerStory.updateCustomerIdEditPage(id),
                        OrmStory.reloadModel(OrmManager.RELOAD_CUSTOMER),
                        RootStory.resetForm('person'),
                        RootStory.resetForm('log'),
                        CustomerStory.updateSaveLockEditPage(false),
                        // SystemStory.navigate(['ao', 'persons', id]),
                        SystemStory.showNotification(SystemNotification.success('Success', 'Customer merged'))
                    ];
                },
                () => [
                    CustomerStory.updateSaveLockEditPage(false),
                ]
            )));
        })
    ),
    action.pipe(
        ofType(CustomerStory.EDIT_PAGE_COUNTRY_SEARCH),
        switchMap(({ payload }: Action<string>) => {
            const rq: DataSearchRqData = {
                where: {
                    name: payload || null,
                    deleted: false
                },
                order: new Order({ by: 'name', isReverse: false })
            };

            return concat(of(CustomerStory.updateSearchCountryLockEditPage(true)), http.post('customers/search-countries', rq).pipe(
                dispatchActions((rs: Response<IDataSearchRs<Array<ICountryData>>>) => [
                    CustomerStory.updateSearchCountryLockEditPage(false),
                    OrmStory.populateCountries(rs.body.results),
                    CustomerStory.updateCountryEditPage(rs.body.results.map(r => r.id))
                ])
            ));
        })
    ),
    action.pipe(
        ofType(CustomerStory.SEND_EMAIL_SEARCH_TEMPLATES),
        switchMap(({ payload }: Action<string>) => {
            const module = state.value.customerModule;
            const { brand, locale } = module.get('sendEmailTemplateForm').toJS();
            const rq: DataSearchRqData = {
                where: {
                    name: payload || null,
                    brand,
                    locale,
                    showDeactivated: false
                },
                order: new Order({ by: 'name', isReverse: false })
            };

            return concat(of(CustomerStory.sendEmailUpdateTemplateLock(true)), http.post('customers/search-email-templates', rq).pipe(
                dispatchActions((rs: Response<IDataSearchRs<Array<IEmailTemplateData>>>) => [
                    OrmStory.populateEmailTemplates(rs.body.results),
                    CustomerStory.sendEmailUpdateTemplates(rs.body.results.map(r => r.id)),
                    CustomerStory.sendEmailUpdateTemplateLock(false)
                ])
            ));
        })
    ),
    action.pipe(
        ofType(CustomerStory.SEND_EMAIL_GET_MESSAGE),
        switchMap(() => {
            const module = state.value.customerModule;
            const id = module.get('editPageRecordId');
            const data = module.get('sendEmailTemplateForm');
            const { brand, locale } = data.toJS();

            return concat(of(CustomerStory.sendEmailUpdateMessageId(null)), http.post(`customers/get-email-message/${ id }`, { templateId: data.getIn(['name', 'id']) }, instanceManager.buildHeaders([brand, locale])).pipe(
                dispatchActions((rs: viewSendEmailData) => {
                    const documentsMapping: Array<IDocumentEmailTemplateMappingData> = [];

                    rs.body.documents.forEach((document) => {
                        documentsMapping.push({
                            templateId: rs.body.message.templateId,
                            documentId: document.id
                        });
                    });

                    return [
                        OrmStory.populateEmailMessages([rs.body.message]),
                        OrmStory.populateDomainDetail([rs.body.domainDetail]),
                        OrmStory.populateDocuments(rs.body.documents),
                        OrmStory.populateDocumentEmailTemplateMappings(documentsMapping),
                        OrmStory.populateUsers([rs.body.user]),
                        CustomerStory.sendEmailUpdateDocuments(rs.body.documents.map(r => r.id)),
                        CustomerStory.sendEmailUpdateMessageId(rs.body.message.id),
                    ];
                })
            ));
        })
    ),
    action.pipe(
        ofType(CustomerStory.SEND_EMAIL_SUBMIT),
        mergeMap(({ payload }: Action<Array<{ id: string, file: File }>>) => {
            const module = state.value.customerModule;
            const selectedIds = module.get('sendEmailDocumentIds');
            const stream = payload.length ? zip(...payload.filter(({ id }) => selectedIds.includes(id)).map(({ file }) => readToBase64(file))) : of([]);

            return concat(stream.pipe(mergeMap(files => {
                const { brand, locale } = module.get('sendEmailTemplateForm').toJS();
                const documents = findById<EmailTemplate>(orm.session(state.value.orm), EmailTemplate, module.getIn(['sendEmailTemplateForm', 'name', 'id'])).relatedDocuments.all().toRefArray();
                const rq = {
                    ...module.get('sendEmailMessageForm').toJS(),
                    id: module.get('editPageRecordId'),
                    documents: documents.map(({ id }) => id).filter(id => selectedIds.includes(id)),
                    files,
                };

                return http.post('customers/send-email', rq, instanceManager.buildHeaders([brand, locale])).pipe(dispatchActions(() => [
                    SystemStory.showNotification(SystemNotification.success('Success', `Message sent.`)),
                ]));
            })));
        }),
    ),
);
