import { Itinerary, IItineraryData } from '@aclass/admin/storage/models/itinerary';
import { IItineraryExpeditionData } from '@aclass/admin/storage/models/itinerary.expedition';
import { ItineraryRoom, IItineraryRoomData } from '@aclass/admin/storage/models/itinerary.room';
import { ITWContractData, TWContract } from '@aclass/admin/storage/models/tw.contract';
import { ITWOptionData, OptionType, TWOption } from '@aclass/admin/storage/models/tw.option';
import { ITWProductData, TWProduct } from '@aclass/admin/storage/models/tw.product';
import { IDateRange } from '@aclass/core/base/daytable';
import { Attr, Fk, Model } from '@aclass/core/decorators/orm.decorator';
import { Model as Entity, QuerySet } from 'redux-orm';

export type Prices = {
    [key in OptionType]?: string;
};

export interface IItineraryDayData {

    readonly id: string;

    readonly date: IDateRange;

    readonly itineraryId: IItineraryData['id'];

    readonly productType: ITWProductData['type'];

    readonly productId: ITWProductData['id'];

    readonly contractId: ITWContractData['id'];

    readonly optionId: ITWOptionData['id'];

    readonly pricingFlag: boolean;

    // readonly showWarning: boolean | null;

    readonly prices: Prices | null;

    readonly expedition: IItineraryExpeditionData | null;

    readonly rooms: Array<Partial<IItineraryRoomData>>;

    // readonly roomsByOptions: Array<any>; // Partial<IItineraryRoomData>>;

    readonly options: Array<Partial<ITWOptionData>> | null;
}

@Model({
    name: 'ItineraryDay'
})
export class ItineraryDay extends Entity<IItineraryDayData> {

    @Attr() readonly id: string;

    @Attr() readonly date: IDateRange;

    @Attr() readonly pricingFlag: string;

    @Attr() readonly prices: Prices | null;

    // @Attr() readonly showWarning: boolean | null;

    @Fk({ to: 'Itinerary', relatedName: 'relatedDays' }) readonly itineraryId: Itinerary;

    @Fk({ to: 'TWProduct', relatedName: 'relatedProductDayInfos' }) readonly productId: TWProduct;

    @Fk({ to: 'TWContract', relatedName: 'relatedProductDayInfos' }) readonly contractId: TWContract;

    @Fk({ to: 'TWOption', relatedName: 'relatedProductDayInfos' }) readonly optionId: TWOption;

    readonly relatedRooms: QuerySet<ItineraryRoom>;

    get product(): TWProduct {
        return this.productId;
    }

    get contract(): TWContract {
        return this.contractId;
    }

    get option(): TWOption {
        return this.optionId;
    }

    get itinerary(): Itinerary {
        return this.itineraryId;
    }
}
