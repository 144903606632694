import {
    I404PageData,
    IAdditionalProductData,
    ICmsAirportData,
    ICmsDestinationData,
    ICmsHotelData,
    ICmsSmalltourData,
    ICmsTourData,
    ICombinationData,
    ICombinationRevisionData,
    ICombinationToTourRevisionMappingData,
    IConsentCustomerMappingData,
    IConsentData,
    IConsentOpportunityMappingData,
    ICountryData,
    ICustomerEmailMessageLogData,
    ICustomerLogData,
    IDeliveryData,
    IDestinationData,
    IDestinationRevisionData,
    IDestinationToTourRevisionMappingData,
    IDocumentCustomerMappingData,
    IDocumentData,
    IDocumentEmailTemplateMappingData,
    IDocumentOfflineBookingMapping,
    IDocumentOpportunityMappingData,
    IDomainDetailData,
    IEmailMessageData,
    IEmailPipelineData,
    IEmailTemplateData,
    IExceptionData,
    IExtraPriceData,
    IGeneralRemarkData,
    IImageData,
    IItineraryData,
    IItineraryDayData,
    IItineraryPriceData,
    IItineraryRoomData,
    IItineraryRoomToPassengerMappingData,
    INotificationData,
    IOfferValidityData,
    IOfficeWorkingHoursData,
    IOfflineBookingEmailData,
    IOfflineBookingPassengerData,
    IOfflineBookingPayment,
    IOpportunityEmailMessageLogData,
    IOpportunityLogData,
    IOverviewConditionData,
    IOverviewData,
    IOverviewDefinitionData,
    IOverviewDisplayData,
    IOverviewReportData,
    IPermissionData,
    IPCCombinationData,
    IPCTourData,
    IPCTourPackageData,
    IQuestionnaireData,
    IQuestionnaireResultData,
    IQuestionData,
    IQuoteGalleryData,
    IQuotePreviewData,
    IQuoteWorkingHoursData,
    IRedirectData,
    IRoleData,
    ISettingData,
    ISignatureData,
    ISmsMessageData,
    ISmsTemplateData,
    ISoldoutProductData,
    ISourceMessageData,
    ISplitTestData,
    ITourData,
    ITourDestinationData,
    ITourRevisionData,
    ITourSettingsData,
    ITranslationMessageData,
    ITWContractData,
    ITWOptionData,
    ITWProductData,
} from '@aclass/admin/storage/models';
import { IVamoosData } from '@aclass/admin/storage/models/vamoos';
import { Story, Task } from '@aclass/core/decorators/storage.decorator';
import { Action } from '@aclass/core/storage/action';
import { ICampaignData } from '@aclass/core/storage/models/campaign';
import { ICustomerData } from '@aclass/core/storage/models/customer';
import { IOfflineBookingData } from '@aclass/core/storage/models/offline.booking';
import { IOpportunityData } from '@aclass/core/storage/models/opportunity';
import { IPipelineLevelData } from '@aclass/core/storage/models/pipeline.level';
import { IQuoteData } from '@aclass/core/storage/models/quote';
import { IQuoteTemplateData } from '@aclass/core/storage/models/quote.template';
import { IUserData } from '@aclass/core/storage/models/user';

@Story({
    name: 'orm'
})
export class OrmStory {

    @Task() static DROP_ADDITIONAL_PRODUCTS: string;

    @Task() static DROP_CAMPAIGNS: string;

    @Task() static DROP_CUSTOMERS: string;

    @Task() static DROP_DOCUMENTS: string;

    @Task() static DROP_LOGS: string;

    @Task() static DROP_EXTRA_PRICE: string;

    @Task() static DROP_GENERAL_REMARKS: string;

    @Task() static DROP_IMAGES: string;

    @Task() static DROP_OVERVIEWS: string;

    @Task() static DROP_QUESTIONS: string;

    @Task() static DROP_OVERVIEW_CONDITIONS: string;

    @Task() static DROP_OVERVIEW_DISPLAYS: string;

    @Task() static DROP_QUOTES: string;

    @Task() static DROP_REDIRECTS: string;

    @Task() static DROP_ROLES: string;

    @Task() static DROP_CONSENTS: string;

    @Task() static DROP_SETTINGS: string;

    @Task() static DROP_SIGNATURES: string;

    @Task() static POPULATE_404_PAGE: string;

    @Task() static POPULATE_CAMPAIGNS: string;

    @Task() static POPULATE_CMS_AIRPORTS: string;

    @Task() static POPULATE_CMS_DESTINATIONS: string;

    @Task() static POPULATE_CMS_HOTELS: string;

    @Task() static POPULATE_CMS_SMALLTOURS: string;

    @Task() static POPULATE_CMS_TOURS: string;

    @Task() static POPULATE_COMBINATIONS: string;

    @Task() static POPULATE_COMBINATION_REVISIONS: string;

    @Task() static POPULATE_CONSENT: string;

    @Task() static POPULATE_COUNTRIES: string;

    @Task() static POPULATE_COMBINATION_TO_TOUR_REVISION_MAPPINGS: string;

    @Task() static POPULATE_CUSTOMERS: string;

    @Task() static POPULATE_CUSTOMER_EMAIL_MESSAGE_LOGS: string;

    @Task() static POPULATE_CUSTOMER_LOGS: string;

    @Task() static POPULATE_DELIVERIES: string;

    @Task() static POPULATE_VAMOOS: string;

    @Task() static POPULATE_DESTINATIONS: string;

    @Task() static POPULATE_DESTINATION_REVISIONS: string;

    @Task() static POPULATE_DESTINATION_TO_TOUR_REVISION_MAPPINGS: string;

    @Task() static POPULATE_DOCUMENTS: string;

    @Task() static POPULATE_TRAVEL_WISE_DOCUMENTS: string;

    @Task() static POPULATE_DOCUMENT_CUSTOMER_MAPPINGS: string;

    @Task() static POPULATE_DOCUMENT_EMAIL_TEMPLATE_MAPPINGS: string;

    @Task() static POPULATE_DOCUMENT_EMAIL_OPTIONS_TEMPLATE_MAPPINGS: string;

    @Task() static POPULATE_DOCUMENT_EMAIL_PIPELINES_TEMPLATE_MAPPINGS: string;

    @Task() static POPULATE_DOCUMENT_OFFLINE_BOOKING_MAPPINGS: string;

    @Task() static POPULATE_DOCUMENT_OPPORTUNITY_MAPPINGS: string;

    @Task() static POPULATE_CONSENT_CUSTOMER_MAPPINGS: string;

    @Task() static POPULATE_CONSENT_OPPORTUNITY_MAPPINGS: string;

    @Task() static POPULATE_DOCUMENT_TRAVEL_WISE_OPPORTUNITY_MAPPINGS: string;

    @Task() static POPULATE_DOMAIN_DETAILS: string;

    @Task() static POPULATE_EMAIL_MESSAGES: string;

    @Task() static POPULATE_EMAIL_PIPELINE: string;

    @Task() static POPULATE_EMAIL_TEMPLATES: string;

    @Task() static POPULATE_EXCEPTIONS: string;

    @Task() static POPULATE_EXTRA_PRICES: string;

    @Task() static POPULATE_GENERAL_REMARK: string;

    @Task() static POPULATE_IMAGES: string;

    @Task() static POPULATE_NOTIFICATION: string;

    @Task() static POPULATE_OFFER_VALIDITY: string;

    @Task() static POPULATE_OFFLINE_BOOKINGS: string;

    @Task() static POPULATE_OFFLINE_BOOKING_EMAIL: string;

    @Task() static POPULATE_OFFLINE_BOOKING_PASSENGERS: string;

    @Task() static POPULATE_OFFLINE_BOOKING_PAYMENTS: string;

    @Task() static POPULATE_OPPORTUNITIES: string;

    @Task() static POPULATE_OPPORTUNITY_EMAIL_MESSAGE_LOGS: string;

    @Task() static POPULATE_OPPORTUNITY_LOGS: string;

    @Task() static POPULATE_OVERVIEWS: string;

    @Task() static POPULATE_OVERVIEW_CONDITIONS: string;

    @Task() static POPULATE_OVERVIEW_DEFINITIONS: string;

    @Task() static POPULATE_OVERVIEW_DISPLAYS: string;

    @Task() static POPULATE_OVERVIEW_REPORTS: string;

    @Task() static POPULATE_PERMISSIONS: string;

    @Task() static POPULATE_PIPELINE_LEVELS: string;

    @Task() static POPULATE_ADDITIONAL_PRODUCTS: string;

    @Task() static POPULATE_ITINERARIES: string;

    @Task() static POPULATE_ITINERARY_DAYS: string;

    @Task() static POPULATE_ITINERARY_ROOMS: string;

    @Task() static POPULATE_ITINERARY_ROOM_TO_PASSENGER_MAPPINGS: string;

    @Task() static POPULATE_ITINERARY_PRICES: string;

    @Task() static POPULATE_PC_TOUR_PACKAGES: string;

    @Task() static POPULATE_PC_TOURS: string;

    @Task() static POPULATE_PC_COMBINATIONS: string;

    @Task() static POPULATE_TW_PRODUCTS: string;

    @Task() static POPULATE_TW_CONTRACTS: string;

    @Task() static POPULATE_TW_OPTIONS: string;

    @Task() static POPULATE_QUESTIONNAIRES: string;

    @Task() static POPULATE_QUESTIONNAIRE_RESULTS: string;

    @Task() static POPULATE_QUESTIONS: string;

    @Task() static POPULATE_QUOTES: string;

    @Task() static POPULATE_QUOTE_GALLERIES: string;

    @Task() static POPULATE_QUOTE_PREVIEWS: string;

    @Task() static POPULATE_QUOTE_TEMPLATES: string;

    @Task() static POPULATE_REDIRECTS: string;

    @Task() static POPULATE_ROLES: string;

    @Task() static POPULATE_SETTINGS: string;

    @Task() static POPULATE_SOLDOUS: string;

    @Task() static POPULATE_SIGNATURES: string;

    @Task() static POPULATE_SMS_MESSAGES: string;

    @Task() static POPULATE_SMS_TEMPLATES: string;

    @Task() static POPULATE_SOURCE_MESSAGES: string;

    @Task() static POPULATE_SPLIT_TESTS: string;

    @Task() static POPULATE_TOURS_DESTINATIONS: string;

    @Task() static POPULATE_TOURS_SETTINGS: string;

    @Task() static POPULATE_TOURS: string;

    @Task() static POPULATE_TOUR_REVISIONS: string;

    @Task() static POPULATE_TRANSLATED_MESSAGES: string;

    @Task() static POPULATE_USERS: string;

    @Task() static POPULATE_QUOTE_WORKING_HOURS: string;

    @Task() static POPULATE_OFFICE_WORKING_HOURS: string;

    @Task() static RELOAD_MODEL: string;

    @Task() static TRUNCATE_DOCUMENT_EMAIL_TEMPLATE_MAPPINGS: string;

    @Task() static UPDATE_ADDITIONAL_PRODUCTS: string;

    @Task() static UPDATE_CAMPAIGNS: string;

    @Task() static UPDATE_DELIVERIES: string;

    @Task() static UPDATE_VAMOOS: string;

    @Task() static UPDATE_CUSTOMERS: string;

    @Task() static UPDATE_USERS: string;

    @Task() static UPDATE_OPPORTUNITIES: string;

    static dropAdditionalProducts(payload: Array<IAdditionalProductData['id']>): Action {
        return { type: OrmStory.DROP_ADDITIONAL_PRODUCTS, payload };
    }

    static dropCampaigns(ids: Array<ICampaignData['id']>): Action {
        return { type: OrmStory.DROP_CAMPAIGNS, payload: ids };
    }

    static dropCustomers(payload: Array<ICustomerData['id']>): Action {
        return { type: OrmStory.DROP_CUSTOMERS, payload };
    }

    static dropDocuments(ids: Array<IDocumentData['id']>): Action {
        return { type: OrmStory.DROP_DOCUMENTS, payload: ids };
    }

    static dropLogs(ids: Array<IDocumentData['id']>): Action {
        return { type: OrmStory.DROP_LOGS, payload: ids };
    }

    static dropExtraPrices(ids: Array<IExtraPriceData['id']>): Action {
        return { type: OrmStory.DROP_EXTRA_PRICE, payload: ids };
    }

    static dropGeneralRemarks(ids: Array<IGeneralRemarkData['id']>): Action {
        return { type: OrmStory.DROP_GENERAL_REMARKS, payload: ids };
    }

    static dropImages(ids: Array<IImageData['id']>): Action {
        return { type: OrmStory.DROP_IMAGES, payload: ids };
    }

    static dropOverviewConditions(ids: Array<IOverviewConditionData['id']>): Action {
        return { type: OrmStory.DROP_OVERVIEW_CONDITIONS, payload: ids };
    }

    static dropOverviewDisplays(ids: Array<IOverviewDisplayData['id']>): Action {
        return { type: OrmStory.DROP_OVERVIEW_DISPLAYS, payload: ids };
    }

    static dropQuestions(ids: Array<IQuestionData['id']>): Action {
        return { type: OrmStory.DROP_QUESTIONS, payload: ids };
    }

    static dropOverviews(ids: Array<IOverviewData['id']>): Action {
        return { type: OrmStory.DROP_OVERVIEWS, payload: ids };
    }

    static dropQuotes(payload: Array<IQuoteData['id']>): Action {
        return { type: OrmStory.DROP_QUOTES, payload };
    }

    static dropRedirects(ids: Array<IRedirectData['id']>): Action {
        return { type: OrmStory.DROP_REDIRECTS, payload: ids };
    }

    static dropRoles(roleNames: Array<IRoleData['name']>): Action {
        return { type: OrmStory.DROP_ROLES, payload: roleNames };
    }

    static dropConsents(ids: Array<IConsentData['id']>): Action {
        return { type: OrmStory.DROP_CONSENTS, payload: ids };
    }

    static dropSettings(ids: Array<ISettingData<any>>): Action {
        return { type: OrmStory.DROP_SETTINGS, payload: ids };
    }

    static dropSignatures(ids: Array<ISignatureData['id']>): Action {
        return { type: OrmStory.DROP_SIGNATURES, payload: ids };
    }

    static populate404Page(payload: Array<Partial<I404PageData>>): Action {
        return { type: OrmStory.POPULATE_404_PAGE, payload };
    }

    static populateCampaigns(payload: Array<ICampaignData>): Action {
        return { type: OrmStory.POPULATE_CAMPAIGNS, payload };
    }

    static populateCmsAirports(payload: Array<ICmsAirportData>): Action {
        return { type: OrmStory.POPULATE_CMS_AIRPORTS, payload };
    }

    static populateCmsDestinations(payload: Array<ICmsDestinationData>): Action {
        return { type: OrmStory.POPULATE_CMS_DESTINATIONS, payload };
    }

    static populateCmsHotels(payload: Array<ICmsHotelData>): Action {
        return { type: OrmStory.POPULATE_CMS_HOTELS, payload };
    }

    static populateCmsSmalltours(payload: Array<ICmsSmalltourData>): Action {
        return { type: OrmStory.POPULATE_CMS_SMALLTOURS, payload };
    }

    static populateCmsTours(payload: Array<ICmsTourData>): Action {
        return { type: OrmStory.POPULATE_CMS_TOURS, payload };
    }

    static populateCombinations(collection: Array<Partial<ICombinationData>>): Action {
        return { type: OrmStory.POPULATE_COMBINATIONS, payload: collection };
    }

    static populateCombinationRevisions(collection: Array<Partial<ICombinationRevisionData>>): Action {
        return { type: OrmStory.POPULATE_COMBINATION_REVISIONS, payload: collection };
    }

    static populateCountries(collection: Array<Partial<ICountryData>>): Action {
        return { type: OrmStory.POPULATE_COUNTRIES, payload: collection };
    }

    static populateCombinationToTourRevisionMappings(collection: Array<Partial<ICombinationToTourRevisionMappingData>>): Action {
        return { type: OrmStory.POPULATE_COMBINATION_TO_TOUR_REVISION_MAPPINGS, payload: collection };
    }

    static populateCustomerEmailMessageLogs(payload: Array<Partial<ICustomerEmailMessageLogData>>): Action {
        return { type: OrmStory.POPULATE_CUSTOMER_EMAIL_MESSAGE_LOGS, payload };
    }

    static populateCustomerLogs(payload: Array<Partial<ICustomerLogData>>): Action {
        return { type: OrmStory.POPULATE_CUSTOMER_LOGS, payload };
    }

    static populateCustomers(payload: Array<ICustomerData>): Action {
        return { type: OrmStory.POPULATE_CUSTOMERS, payload };
    }

    static populateDeliveries(payload: Array<Partial<IDeliveryData>>): Action {
        return { type: OrmStory.POPULATE_DELIVERIES, payload };
    }

    static populateVamoos(payload: Array<Partial<IVamoosData>>): Action {
        return { type: OrmStory.POPULATE_VAMOOS, payload };
    }

    static populateDestinations(collection: Array<Partial<IDestinationData>>): Action {
        return { type: OrmStory.POPULATE_DESTINATIONS, payload: collection };
    }

    static populateDestinationRevisions(collection: Array<Partial<IDestinationRevisionData>>): Action {
        return { type: OrmStory.POPULATE_DESTINATION_REVISIONS, payload: collection };
    }

    static populateDestinationToTourRevisionMappings(collection: Array<Partial<IDestinationToTourRevisionMappingData>>): Action {
        return { type: OrmStory.POPULATE_DESTINATION_TO_TOUR_REVISION_MAPPINGS, payload: collection };
    }

    static populateDocumentCustomerMappings(payload: Array<Partial<IDocumentCustomerMappingData>>): Action {
        return { type: OrmStory.POPULATE_DOCUMENT_CUSTOMER_MAPPINGS, payload };
    }

    static populateDocumentEmailTemplateMappings(payload: Array<Partial<IDocumentEmailTemplateMappingData>>): Action {
        return { type: OrmStory.POPULATE_DOCUMENT_EMAIL_TEMPLATE_MAPPINGS, payload };
    }

    static populateDocumentOfflineBookingMappings(payload: Array<IDocumentOfflineBookingMapping>): Action {
        return { type: OrmStory.POPULATE_DOCUMENT_OFFLINE_BOOKING_MAPPINGS, payload };
    }

    static populateDocumentOpportunityMappings(payload: Array<Partial<IDocumentOpportunityMappingData>>): Action {
        return { type: OrmStory.POPULATE_DOCUMENT_OPPORTUNITY_MAPPINGS, payload };
    }

    static populateConsentOpportunityMappings(payload: Array<Partial<IConsentOpportunityMappingData>>): Action {
        return { type: OrmStory.POPULATE_CONSENT_OPPORTUNITY_MAPPINGS, payload };
    }

    static populateConsentCustomerMappings(payload: Array<Partial<IConsentCustomerMappingData>>): Action {
        return { type: OrmStory.POPULATE_CONSENT_CUSTOMER_MAPPINGS, payload };
    }

    static populateDocumentTravelWiseOpportunityMappings(payload: Array<Partial<IDocumentOpportunityMappingData>>): Action {
        return { type: OrmStory.POPULATE_DOCUMENT_TRAVEL_WISE_OPPORTUNITY_MAPPINGS, payload };
    }

    static populateDocuments(payload: Array<Partial<IDocumentData>>): Action {
        return { type: OrmStory.POPULATE_DOCUMENTS, payload };
    }

    static populateTravelWiseDocuments(payload: Array<Partial<IDocumentData>>): Action {
        return { type: OrmStory.POPULATE_TRAVEL_WISE_DOCUMENTS, payload };
    }

    static populateDomainDetail(payload: Array<Partial<IDomainDetailData>>): Action {
        return { type: OrmStory.POPULATE_DOMAIN_DETAILS, payload };
    }

    static populateConsent(payload: Array<Partial<IConsentData>>): Action {
        return { type: OrmStory.POPULATE_CONSENT, payload };
    }

    static populateEmailMessages(payload: Array<Partial<IEmailMessageData>>): Action {
        return { type: OrmStory.POPULATE_EMAIL_MESSAGES, payload };
    }

    static populateEmailPipelines(payload: Array<Partial<IEmailPipelineData>>): Action {
        return { type: OrmStory.POPULATE_EMAIL_PIPELINE, payload };
    }

    static populateEmailTemplates(payload: Array<Partial<IEmailTemplateData>>): Action {
        return { type: OrmStory.POPULATE_EMAIL_TEMPLATES, payload };
    }

    static populateExceptions(payload: Array<Partial<IExceptionData>>): Action {
        return { type: OrmStory.POPULATE_EXCEPTIONS, payload };
    }

    static populateExtraPrices(payload: Array<Partial<IExtraPriceData>>): Action {
        return { type: OrmStory.POPULATE_EXTRA_PRICES, payload };
    }

    static populateGeneralRemarks(payload: Array<Partial<IGeneralRemarkData>>): Action {
        return { type: OrmStory.POPULATE_GENERAL_REMARK, payload };
    }

    static populateImages(payload: Array<Partial<IImageData>>): Action {
        return { type: OrmStory.POPULATE_IMAGES, payload };
    }

    static populateNotification(payload: Array<Partial<INotificationData>>): Action {
        return { type: OrmStory.POPULATE_NOTIFICATION, payload };
    }

    static populateOfferValidity(payload: Array<Partial<IOfferValidityData>>): Action {
        return { type: OrmStory.POPULATE_OFFER_VALIDITY, payload };
    }

    static populateOfflineBookingEmails(payload: Array<Partial<IOfflineBookingEmailData>>): Action {
        return { type: OrmStory.POPULATE_OFFLINE_BOOKING_EMAIL, payload };
    }

    static populateOfflineBookingPassengers(payload: Array<Partial<IOfflineBookingPassengerData>>): Action {
        return { type: OrmStory.POPULATE_OFFLINE_BOOKING_PASSENGERS, payload };
    }

    static populateOfflineBookingPayments(payload: Array<Partial<IOfflineBookingPayment>>): Action {
        return { type: OrmStory.POPULATE_OFFLINE_BOOKING_PAYMENTS, payload };
    }

    static populateOfflineBookings(payload: Array<IOfflineBookingData>): Action {
        return { type: OrmStory.POPULATE_OFFLINE_BOOKINGS, payload };
    }

    static populateOpportunities(payload: Array<Partial<IOpportunityData>>): Action {
        return { type: OrmStory.POPULATE_OPPORTUNITIES, payload };
    }

    static populateOpportunityEmailMessageLogs(payload: Array<Partial<IOpportunityEmailMessageLogData>>): Action {
        return { type: OrmStory.POPULATE_OPPORTUNITY_EMAIL_MESSAGE_LOGS, payload };
    }

    static populateOpportunityLogs(payload: Array<Partial<IOpportunityLogData>>): Action {
        return { type: OrmStory.POPULATE_OPPORTUNITY_LOGS, payload };
    }

    static populateOverviewConditions(payload: Array<Partial<IOverviewConditionData>>): Action {
        return { type: OrmStory.POPULATE_OVERVIEW_CONDITIONS, payload };
    }

    static populateOverviewDefinitions(payload: Array<Partial<IOverviewDefinitionData>>): Action {
        return { type: OrmStory.POPULATE_OVERVIEW_DEFINITIONS, payload };
    }

    static populateOverviewDisplays(payload: Array<Partial<IOverviewDisplayData>>): Action {
        return { type: OrmStory.POPULATE_OVERVIEW_DISPLAYS, payload };
    }

    static populateOverviewReports(payload: Array<Partial<IOverviewReportData>>): Action {
        return { type: OrmStory.POPULATE_OVERVIEW_REPORTS, payload };
    }

    static populateOverviews(payload: Array<Partial<IOverviewData>>): Action {
        return { type: OrmStory.POPULATE_OVERVIEWS, payload };
    }

    static populatePermissions(payload: Array<Partial<IPermissionData>>): Action {
        return { type: OrmStory.POPULATE_PERMISSIONS, payload };
    }

    static populatePipelineLevels(payload: Array<IPipelineLevelData>): Action {
        return { type: OrmStory.POPULATE_PIPELINE_LEVELS, payload };
    }

    static populateAdditionalProducts(payload: Array<Partial<IAdditionalProductData>>): Action {
        return { type: OrmStory.POPULATE_ADDITIONAL_PRODUCTS, payload };
    }

    static populateQuestionnaires(payload: Array<Partial<IQuestionnaireData>>): Action {
        return { type: OrmStory.POPULATE_QUESTIONNAIRES, payload };
    }

    static populateQuestionnaireResults(payload: Array<Partial<IQuestionnaireResultData>>): Action {
        return { type: OrmStory.POPULATE_QUESTIONNAIRE_RESULTS, payload };
    }

    static populateQuestions(payload: Array<Partial<IQuestionData>>): Action {
        return { type: OrmStory.POPULATE_QUESTIONS, payload };
    }

    static populateQuoteGalleries(payload: Array<Partial<IQuoteGalleryData>>): Action {
        return { type: OrmStory.POPULATE_QUOTE_GALLERIES, payload };
    }

    static populateQuotePreviews(payload: Array<Partial<IQuotePreviewData>>): Action {
        return { type: OrmStory.POPULATE_QUOTE_PREVIEWS, payload };
    }

    static populateQuotes(payload: Array<IQuoteData>): Action {
        return { type: OrmStory.POPULATE_QUOTES, payload };
    }

    static populateQuoteTemplate(payload: Array<IQuoteTemplateData>): Action {
        return { type: OrmStory.POPULATE_QUOTE_TEMPLATES, payload };
    }

    static populateQuoteTemplates(payload: Array<IQuoteTemplateData>): Action {
        return { type: OrmStory.POPULATE_QUOTE_TEMPLATES, payload };
    }

    static populateRedirects(payload: Array<Partial<IRedirectData>>): Action {
        return { type: OrmStory.POPULATE_REDIRECTS, payload };
    }

    static populateRoles(payload: Array<Partial<IRoleData>>): Action {
        return { type: OrmStory.POPULATE_ROLES, payload };
    }

    static populateSettings(payload: Array<Partial<ISettingData<any>>>): Action {
        return { type: OrmStory.POPULATE_SETTINGS, payload };
    }

    static populateSoldouts(payload: Array<Partial<ISoldoutProductData>>): Action {
        return { type: OrmStory.POPULATE_SOLDOUS, payload };
    }

    static populateSignatures(payload: Array<Partial<ISignatureData>>): Action {
        return { type: OrmStory.POPULATE_SIGNATURES, payload };
    }

    static populateSmsMessages(payload: Array<Partial<ISmsMessageData>>): Action {
        return { type: OrmStory.POPULATE_SMS_MESSAGES, payload };
    }

    static populateSmsTemplates(payload: Array<Partial<ISmsTemplateData>>): Action {
        return { type: OrmStory.POPULATE_SMS_TEMPLATES, payload };
    }

    static populateSourceMessages(payload: Array<Partial<ISourceMessageData>>): Action {
        return { type: OrmStory.POPULATE_SOURCE_MESSAGES, payload };
    }

    static populateSplitTests(payload: Array<Partial<ISplitTestData>>): Action {
        return { type: OrmStory.POPULATE_SPLIT_TESTS, payload };
    }

    static populateTours(collection: Array<Partial<ITourData>>): Action {
        return { type: OrmStory.POPULATE_TOURS, payload: collection };
    }

    static populateTourRevisions(collection: Array<Partial<ITourRevisionData>>): Action {
        return { type: OrmStory.POPULATE_TOUR_REVISIONS, payload: collection };
    }

    static populateToursDestinations(payload: Array<Partial<ITourDestinationData>>): Action {
        return { type: OrmStory.POPULATE_TOURS_DESTINATIONS, payload };
    }

    static populateToursSettings(payload: Array<Partial<ITourSettingsData>>): Action {
        return { type: OrmStory.POPULATE_TOURS_SETTINGS, payload };
    }

    static populateItineraries(payload: Array<Partial<IItineraryData>>): Action {
        return { type: OrmStory.POPULATE_ITINERARIES, payload };
    }

    static populateItineraryDays(payload: Array<Partial<IItineraryDayData>>): Action {
        return { type: OrmStory.POPULATE_ITINERARY_DAYS, payload };
    }

    static populateItineraryRooms(payload: Array<Partial<IItineraryRoomData>>): Action {
        return { type: OrmStory.POPULATE_ITINERARY_ROOMS, payload };
    }

    static populateItineraryRoomToPassengerMappings(payload: Array<Partial<IItineraryRoomToPassengerMappingData>>): Action {
        return { type: OrmStory.POPULATE_ITINERARY_ROOM_TO_PASSENGER_MAPPINGS, payload };
    }

    static populateItineraryPrices(payload: Array<Partial<IItineraryPriceData>>): Action {
        return { type: OrmStory.POPULATE_ITINERARY_PRICES, payload };
    }

    static populatePcTourPackages(payload: Array<Partial<IPCTourPackageData>>): Action {
        return { type: OrmStory.POPULATE_PC_TOUR_PACKAGES, payload };
    }

    static populatePcTours(payload: Array<Partial<IPCTourData>>): Action {
        return { type: OrmStory.POPULATE_PC_TOURS, payload };
    }

    static populatePcCombinations(payload: Array<Partial<IPCCombinationData>>): Action {
        return { type: OrmStory.POPULATE_PC_COMBINATIONS, payload };
    }

    static populateTwProducts(payload: Array<Partial<ITWProductData>>): Action {
        return { type: OrmStory.POPULATE_TW_PRODUCTS, payload };
    }

    static populateTwContracts(payload: Array<Partial<ITWContractData>>): Action {
        return { type: OrmStory.POPULATE_TW_CONTRACTS, payload };
    }

    static populateTwOptions(payload: Array<Partial<ITWOptionData>>): Action {
        return { type: OrmStory.POPULATE_TW_OPTIONS, payload };
    }

    static populateTranslatedMessages(payload: Array<Partial<ITranslationMessageData>>): Action {
        return { type: OrmStory.POPULATE_TRANSLATED_MESSAGES, payload };
    }

    static populateUsers(payload: Array<IUserData>): Action {
        return { type: OrmStory.POPULATE_USERS, payload };
    }

    static populateQuoteWorkingHours(payload: Array<Partial<IQuoteWorkingHoursData>>): Action {
        return { type: OrmStory.POPULATE_QUOTE_WORKING_HOURS, payload };
    }

    static populateOfficeWorkingHours(payload: Array<Partial<IOfficeWorkingHoursData>>): Action {
        return { type: OrmStory.POPULATE_OFFICE_WORKING_HOURS, payload };
    }

    static reloadModel(name: string): Action {
        return { type: OrmStory.RELOAD_MODEL, payload: name };
    }

    static truncateDocumentEmailTemplateMappings(): Action {
        return { type: OrmStory.TRUNCATE_DOCUMENT_EMAIL_TEMPLATE_MAPPINGS };
    }

    static updateAdditionalProducts(payload: Array<Partial<IAdditionalProductData>>): Action {
        return { type: OrmStory.UPDATE_ADDITIONAL_PRODUCTS, payload };
    }

    static updateCampaigns(payload: Array<Partial<ICampaignData>>): Action {
        return { type: OrmStory.UPDATE_CAMPAIGNS, payload };
    }

    static updateDeliveries(payload: Array<Partial<IDeliveryData>>): Action {
        return { type: OrmStory.UPDATE_DELIVERIES, payload };
    }

    static updateVamoos(payload: Array<Partial<IVamoosData>>): Action {
        return { type: OrmStory.UPDATE_VAMOOS, payload };
    }

    static updateCustomers(payload: Array<Partial<ICustomerData>>): Action {
        return { type: OrmStory.UPDATE_CUSTOMERS, payload };
    }

    static updateUsers(payload: Array<Partial<IUserData>>): Action {
        return { type: OrmStory.UPDATE_USERS, payload };
    }

    static updateOpportunities(payload: Array<Partial<IOpportunityData>>): Action {
        return { type: OrmStory.UPDATE_OPPORTUNITIES, payload };
    }
}
