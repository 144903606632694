import { ORM } from 'redux-orm';
import {
    AdditionalProduct,
    Campaign,
    CmsAirport,
    CmsDestination,
    CmsHotel,
    CmsSmalltour,
    CmsTour,
    Combination,
    CombinationRevision,
    CombinationToTourRevisionMapping,
    Consent,
    ConsentCustomerMapping,
    ConsentOpportunityMapping,
    Country,
    Customer,
    CustomerEmailMessageLog,
    CustomerLog,
    Delivery,
    Destination,
    DestinationRevision,
    DestinationToTourRevisionMapping,
    Document,
    DocumentCustomerMapping,
    DocumentEmailTemplateMapping,
    DocumentOfflineBookingMapping,
    DocumentOpportunityMapping,
    DocumentTravelWiseOpportunityMapping,
    DomainDetail,
    EmailMessage,
    EmailPipeline,
    EmailTemplate,
    Exception,
    ExtraPrice,
    GeneralRemark,
    Image,
    Itinerary,
    ItineraryDay,
    ItineraryPrice,
    ItineraryRoom,
    ItineraryRoomToPassengerMapping,
    Notification,
    OfferValidity,
    OfficeWorkingHours,
    OfflineBooking,
    OfflineBookingEmail,
    OfflineBookingPassenger,
    OfflineBookingPayment,
    Opportunity,
    OpportunityEmailMessageLog,
    OpportunityLog,
    Overview,
    OverviewCondition,
    OverviewDefinition,
    OverviewDisplay,
    OverviewReport,
    Page404,
    Permission,
    PipelineLevel,
    PCCombination,
    PCTour,
    PCTourPackage,
    Question,
    Questionnaire,
    QuestionnaireResult,
    Quote,
    QuoteGallery,
    QuotePreview,
    QuoteTemplate,
    QuoteWorkingHours,
    Redirect,
    Role,
    Setting,
    Signature,
    SmsMessage,
    SmsTemplate,
    SoldoutProduct,
    SourceMessage,
    SplitTest,
    Tour,
    TourDestinations,
    TourRevision,
    TourSettings,
    TranslationMessage,
    TravelWiseDocument,
    TWContract,
    TWExcursionOption,
    TWHotelOption,
    TWOption,
    TWProduct,
    TWTransferOption,
    User,
    Vamoos,
} from './models';

const MODELS = {
    AdditionalProduct,
    Campaign,
    CmsAirport,
    CmsDestination,
    CmsHotel,
    CmsSmalltour,
    CmsTour,
    Combination,
    CombinationRevision,
    CombinationToTourRevisionMapping,
    Consent,
    ConsentCustomerMapping,
    ConsentOpportunityMapping,
    Country,
    Customer,
    CustomerEmailMessageLog,
    CustomerLog,
    Delivery,
    Destination,
    DestinationRevision,
    DestinationToTourRevisionMapping,
    Document,
    DocumentCustomerMapping,
    DocumentEmailTemplateMapping,
    DocumentOfflineBookingMapping,
    DocumentOpportunityMapping,
    DocumentTravelWiseOpportunityMapping,
    DomainDetail,
    Exception,
    EmailMessage,
    EmailPipeline,
    EmailTemplate,
    ExtraPrice,
    GeneralRemark,
    Image,
    Itinerary,
    ItineraryDay,
    ItineraryPrice,
    ItineraryRoom,
    ItineraryRoomToPassengerMapping,
    Notification,
    OfferValidity,
    OfficeWorkingHours,
    OfflineBooking,
    OfflineBookingEmail,
    OfflineBookingPassenger,
    OfflineBookingPayment,
    Opportunity,
    OpportunityEmailMessageLog,
    OpportunityLog,
    Overview,
    OverviewCondition,
    OverviewDefinition,
    OverviewDisplay,
    OverviewReport,
    Page404,
    Permission,
    PipelineLevel,
    PCCombination,
    PCTour,
    PCTourPackage,
    Question,
    Questionnaire,
    QuestionnaireResult,
    Quote,
    QuoteGallery,
    QuotePreview,
    QuoteTemplate,
    QuoteWorkingHours,
    Redirect,
    Role,
    Setting,
    Signature,
    SmsMessage,
    SmsTemplate,
    SoldoutProduct,
    SourceMessage,
    SplitTest,
    Tour,
    TourDestinations,
    TourRevision,
    TourSettings,
    TranslationMessage,
    TravelWiseDocument,
    TWContract,
    TWExcursionOption,
    TWHotelOption,
    TWOption,
    TWProduct,
    TWTransferOption,
    Vamoos,
    User,
};

export const orm = new ORM();

orm.register.apply(orm, Object.values(MODELS));
